import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { IconType } from 'react-icons';
import Detran from '../Person/Detran';
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';

/** components */
import Layout from '../../../components/Layout';
import Address from '../Address';
import Vehicle from '../Vehicle';
import Person from '../Person';
import Proximity from '../Proximity';

import {
  CarIcon, PersonIcon, HandsHelpingIcon,
} from '../../../styles/icons';

import { Container, WrapperAddress } from './styles';

interface ICoord {
  lat: number;
  lng: number;
}

interface IService {
  id: string;
}

interface IType {
  iconS: IconType;
  titleS: string;
}

const Order: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const { httpGet, loading } = useHttp();
  const { user } = useAuth();
  const [coord, setCoords] = useState<ICoord>({} as ICoord);
  const [service, setService] = useState<IService | null>(null);

  let iType: IType;

  switch (type) {
    case 'vehicle':
      iType = {
        iconS: CarIcon,
        titleS: 'Consultar',
      };
      break;
    case 'proximity':
      iType = {
        iconS: HandsHelpingIcon,
        titleS: 'Abordagem de Proximidade',
      };
      break;
    default:
      iType = {
        iconS: PersonIcon,
        titleS: 'Consultar',
      };
  }
  const breadcrumbs = {
    icon: iType.iconS,
    links: [{ path: '', title: iType.titleS }],
  };

  useEffect(() => {
    async function load(): Promise<any> {
      const management_id = user?.allocation?.management_id;
      const { status, data } = await httpGet(`/orders/auth/${management_id}`);

      if (status === 200) {
        setService(data);
        return;
      }

      setService(null);
      history.push('/dashboard');
    }

    load();
  }, [history, httpGet, user]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        {!coord.lat && service && (
          <WrapperAddress>
            <h3><span>Selecione o </span>local da abordagem</h3>
            <Address
              handleCoord={(coord: ICoord) => {
                setCoords(coord);
              }}
            />
          </WrapperAddress>
        )}
        {(coord.lat && type === 'vehicle') && (
          <Vehicle coord={coord} />
        )}
        {(coord.lat && type === 'person') && (
          <Person coord={coord} />
        )}
        {(coord.lat && type === 'detran') && (
          <Detran coord={coord} />
        )}
        {(coord.lat && type === 'proximity') && (
          <Proximity coord={coord} />
        )}
      </Container>
    </Layout>
  );
};

export default Order;
