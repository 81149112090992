import React from 'react';
import ModalFactory from 'react-modal-promise';
import { distanceDateTime } from 'utils';

import IUser from 'interfaces/user';
import { Container } from './styles';
import Flex, { Grid } from '../../../../components/Flex';

interface IService {
  id: string;
  user: IUser;
  service_name: string;
  created_at: string;
  server_time: string;
}

// eslint-disable-next-line arrow-body-style
const Card: React.FC<{ service: IService }> = ({ service }) => {
  return (
    <Container>
      <Grid container spacing={1}>
        <Flex title="Responsável" xs={6} sm={6} md={4}>
          {service.user.name || 'Não cadastrado'}
        </Flex>
        <Flex title="Serviço" xs={6} sm={6} md={4}>
          {service.service_name}
        </Flex>
        <Flex title="Criado há" xs={6} sm={6} md={4}>
          {distanceDateTime(service.created_at, service.server_time, false)}
        </Flex>
      </Grid>
      <ModalFactory />
    </Container>
  );
};

export default Card;
