import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { IGas } from 'interfaces';
import getValidationError from '../../../utils/getValidationsErros';
import {
  WrapperLabel,
  Input,
  InputMask,
  InputHidden,
  Select,
  ButtonIcon,
  Button,
} from '../../../components/Forms';
import {
  Container,
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
} from './styles';
import { InfoIcon } from '../../../styles/icons';
import { useToast } from '../../../hooks/toast';
import { useHttp } from '../../../hooks/http';

interface IForm {
    gas: IGas;
}

interface IEnd {
    logradouro?: string;
    complemento?: string;
    bairro?: string;
    localidade?: string;
    uf?: string
}

const Profile: React.FC<IForm> = ({ gas }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const {
    httpPost, httpPut, loading, unLoading,
  } = useHttp();
  const [statusOptions] = useState([
    { value: true, label: 'Ativo' },
    { value: false, label: 'Bloqueado' },
  ]);
  const [address, setAddress] = useState<IEnd | undefined>({} as IEnd);

  useEffect(() => {
    unLoading();
    if (gas) {
            formRef.current?.setData({
              ...gas,
            });
    }
    }, [gas]); // eslint-disable-line

  const handleBlur = useCallback(async (cep: string) => {
    if (cep.length < 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`, { mode: 'cors' })
      .then((res) => res.json())
      .then((data) => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('erro')) {
          return;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('erro')) {
          return;
        }
        setAddress(data);
      }).catch();
  }, []);
  
  const handleSubmit = useCallback(async (payload: any) => {
    const _gas = {
      ...payload,
      fantasy: payload.fantasy.toUpperCase(),
      corporate: payload.corporate.toUpperCase(),
      cep: payload.cep.replace(/\D/g, ''),
      address: payload.address.toUpperCase(),
      district: payload.district.toUpperCase(),
      city: payload.city.toUpperCase(),
      state: payload.state.toUpperCase(),
      status: payload?.status?.value,
    };

        formRef.current?.setErrors({});

        try {
          const schema = Yup.object().shape({
            fantasy: Yup.string().required('Nome Fantasia é obrigatório'),
            corporate: Yup.string().required('Razão Social é obrigatório'),
            status: Yup.string().required('Status é obrigatória'),
          });

          await schema.validate(_gas, { abortEarly: false });

          if (gas.id) {
            const { status } = await httpPut(`gas/${_gas.id}`, _gas);
            if (status === 200) {
              addToast({ title: 'Alterado com sucesso!', type: 'warning' });
              history.push('/gases');
            }
          } else {
            const { status } = await httpPost('gas', _gas);
            if (status === 200) {
              addToast({ title: 'Salvo com sucesso!', type: 'success' });
              history.push('/gases');
            }
          }
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
          }
        }
  }, [gas, httpPost, httpPut, history, addToast]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <InputHidden name="id" />
        <Grid container spacing={1}>
          <WrapperLabel label="Nome Fantasia" xs={12} sm={6}>
            <Input
              name="fantasy"
              maxLength={50}
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Razão Social" xs={12} sm={6}>
            <Input
              name="corporate"
              maxLength={50}
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Cnpj" xs={12} sm={6}>
            <InputMask
              name="cnpj"
              mask="99.999.999/9999-99"
            />
          </WrapperLabel>
          <WrapperLabel label="Telefone" xs={12} sm={6} md={6}>
            <Input name="telephone" />
          </WrapperLabel>
          <WrapperLabel label="CEP" xs={12} sm={3}>
            <InputMask name="cep" mask="99999-999" onBlur={(e) => handleBlur(e.target.value)} />
          </WrapperLabel>
          <WrapperLabel label="Endereço" xs={12} sm={9}>
            <Input name="address" defaultValue={address?.logradouro || undefined} maxLength={50} style={{ textTransform: 'uppercase' }} />
          </WrapperLabel>
          <WrapperLabel label="Distrito" xs={12} sm={6}>
            <Input name="district" defaultValue={address?.bairro || undefined} maxLength={20} style={{ textTransform: 'uppercase' }} />
          </WrapperLabel>
          <WrapperLabel label="Cidade" xs={10} sm={5}>
            <Input name="city" defaultValue={address?.localidade || undefined} maxLength={20} style={{ textTransform: 'uppercase' }} />
          </WrapperLabel>
          <WrapperLabel label="Estado" xs={2} sm={1}>
            <Input name="state" maxLength={2} defaultValue={address?.uf || undefined} style={{ textTransform: 'uppercase' }} />
          </WrapperLabel>
          <WrapperLabel label="Status" xs={12} sm={12} md={6}>
            <Select
              name="status"
              options={statusOptions}
            />
          </WrapperLabel>
        </Grid>
        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={loading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
    </Container>
  );
};

export default Profile;
