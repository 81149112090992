// biome-ignore lint/style/useImportType: <explanation>
import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
// biome-ignore lint/style/useImportType: <explanation>
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface ReactSelectProps extends SelectProps<OptionTypeBase>{
  name: string;
  message?: string;
  transform?: 'uppercase' | 'default' ;
  isDisabled?: boolean,
}

const Select: React.FC<ReactSelectProps> = ({
  name,
  options,
  transform = 'default',
  message = 'Sem registros',
  isDisabled = false,
  ...rest
}) => {
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const {
    fieldName, defaultValue, registerField, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => ref.state.value,
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: (ref) => {
        ref.select.clearValue();
      },
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const style = {
    control: (base: any) => ({
      ...base,
      border: 0,
      background: 'transparent',
      boxShadow: 'none',
    }),
  };

  const styleUpper = {
    control: (base: any) => ({
      ...base,
      border: 0,
      background: 'transparent',
      boxShadow: 'none',
      textTransform: 'uppercase',
    }),
    option: (provided:any) => ({
      ...provided,
      textTransform: 'uppercase',
    }),
  };

  return (

    <Container
      isErrored={!!error}
      isFocused={isFocused}
      transform={transform}
      disable={isDisabled}
    >

      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        styles={transform === 'uppercase' ? styleUpper : style}
        onFocus={handleFocused}
        onBlur={handleInputBlur}
        classNamePrefix="react-select"
        options={options}
        placeholder="Selecione"
        noOptionsMessage={() => message}
        isDisabled={isDisabled}
        {...rest}
      />

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
    </Container>

  );
};

export default Select;
