import { IPaginate } from 'interfaces';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { GoChecklist } from 'react-icons/go';
import { useHttp } from 'hooks/http';
import { useQuery } from 'react-query';
import { Box, Grid } from '@material-ui/core';
import HeaderFilterPlus from '../../../components/Headers/FilterPlus';
import Layout from '../../../components/Layout';
import { Container } from './styles';
import Pagination from '../../../components/Pagination';
import Card from './Card';

interface IFilter {
    search?: string;
}

const PartnersList : React.FC = () => {
  const { httpGet } = useHttp();
  const [partners, setPartners] = useState([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const fetchPartnersSocialAction = useCallback(async () => {
    const response = await httpGet('social-action/partners', {
      params: {
        filter,
        ...paginate,
      },
    });
    return response.data;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, paginate?.page]);

  const { data, status } = useQuery(['partners', paginate, filter], fetchPartnersSocialAction, {
    refetchOnWindowFocus: false, refetchInterval: 10000, retry: false,
  });

  useEffect(() => {
    if (status === 'success') {
      setPartners(data?.partners || data);
      setPaginate(data?.pagination || {
        page: 1, perPage: 10, lastPage: 1, total: 0,
      });
    }
  }, [data, filter, status]);

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter({ search: value });
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate((prev) => ({
      ...prev,
      page: payload.page || 1,
      lastPage: payload.lastPage || 1,
    }));
  };

  const breadcrumbs = {
    icon: GoChecklist,
    links: [{ path: '', title: 'Lista de Parceiros' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />

        <Grid container spacing={3} justify="space-between">
          <Box p={2}><b>Total</b></Box>
          <Box p={2}><b>{partners?.length}</b></Box>
        </Grid>

        {partners?.map((partner:any) => (
          <Card partner={partner} key={partner.id} />
        ))}

        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default PartnersList;
