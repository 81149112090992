import React from 'react';
import ModalFactory from 'react-modal-promise';
import { distanceDateTime } from 'utils';

import IUser from 'interfaces/user';
import { Container } from './styles';
import Flex, { Grid } from '../../../../components/Flex';

interface IPartner {
  id: string;
  user: IUser;
  partner_name: string;
  created_at: string;
  server_time: string;
}

// eslint-disable-next-line arrow-body-style
const Card: React.FC<{ partner: IPartner }> = ({ partner }) => {
  return (
    <Container>
      <Grid container spacing={1}>
        <Flex title="Responsável" xs={6} sm={6} md={4}>
          {partner.user.name || 'Não cadastrado'}
        </Flex>
        <Flex title="Parceiro" xs={6} sm={6} md={4}>
          {partner.partner_name}
        </Flex>
        <Flex title="Criado há" xs={6} sm={6} md={4}>
          {distanceDateTime(partner.created_at, partner.server_time, false)}
        </Flex>
      </Grid>
      <ModalFactory />
    </Container>
  );
};

export default Card;
