import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 8px 16px;

  form{
    width: 100%;
  }
`;

export const Content = styled.div`
  background: #fff;
  padding: 32px 16px;
  border-radius: 4px;
`;

export const Content2 = styled.div`
  background: #fff;
  padding: 32px 16px;
  border-radius: 4px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  display:flex;
  align-items:center;

  span{
    font-size: 20px;
    margin-left: 8px;
    text-decoration: line-through;
  }

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
  `;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

export const DrawerContent = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  form{
    width: 100%;
  }
`;
