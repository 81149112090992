import Layout from 'components/Layout';
import { useHttp } from 'hooks/http';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import { useToast } from 'hooks/toast';
import { EventNoteIcon, PlusIcon } from 'styles/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import {
  Button, ButtonIcon, Select, WrapperLabel, Input,
} from 'components/Forms';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FaRegTrashAlt } from 'react-icons/fa';
import { bairrosRJ, municipiosRJ, regioesRJ } from 'utils/regioesRJ';
import getValidationError from 'utils/getValidationsErros';
import {
  Container, Content, GridButtons, WrapperBtnCancel, WrapperBtnSubmit,
} from './styles';

interface ISocialAction {
  regiao: string;
  bairro: string;
  municipio: string;
  data_acao: string;
  partner_name: string;
  service_name: string;
  quantity: string;
}

interface IServiceRow {
  partner: { value: string; label: string };
  service: { value: string; label: string };
  quantity: number;
  services: Array<{ value: string; label: string }>;
}

const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { httpGet, httpPut, loading } = useHttp();
  const { addToast } = useToast();
  const [btnLoading, setBtnLoading] = useState(false);
  const [partners, setPartners] = useState<Array<{ value: string; label: string }>>([]);
  const [bairro, setBairro] = useState('');
  const [isBairroDisabled, setIsBairroDisabled] = useState(true);
  const [bairroOptions, setBairroOptions] = useState<{ value: string; label: string }[]>([]);
  const [filteredMunicipios, setFilteredMunicipios] = useState<{ value: string; label: string; region: string }[]>([]);
  // const [selectedRegiao, setSelectedRegiao] = useState<string | null>(null);
  const [selectedServices, setSelectedServices] = useState<{ [partnerId: string]: string[] }>({});
  const [selectedMunicipio, setSelectedMunicipio] = useState<string | null>(null);
  const [serviceRows, setServiceRows] = useState<IServiceRow[]>([]);

  function formatDateForInput(dateString: string): string {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    async function loadActionDetails(): Promise<void> {
      const { status, data } = await httpGet(`/social-action/${id}`);
      if (status === 200) {
        const actionDetails = data;

        const formattedDate = formatDateForInput(actionDetails.data_acao);

        formRef.current?.setData({
          data_acao: formattedDate,
          regiao: { value: actionDetails.regiao, label: actionDetails.regiao },
          municipio: { value: actionDetails.municipio, label: actionDetails.municipio },
          bairro: { value: actionDetails.bairro, label: actionDetails.bairro },
        });

        const rows = actionDetails.details.map((detail: any) => ({
          partner: { value: detail.partner_id, label: detail.partner_name },
          service: { value: detail.service_id, label: detail.service_name },
          quantity: detail.quantity,
          services: [],
        }));

        setServiceRows(rows);
      }
    }

    loadActionDetails();
  }, [id, httpGet]);

  const addNewServiceRow = () => {
    setServiceRows([...serviceRows, {
      partner: { value: '', label: '' }, service: { value: '', label: '' }, quantity: 0, services: [],
    }]);
  };

  const handleServiceRowChange = (index: number, field: keyof IServiceRow, value: any) => {
    const updatedRows = [...serviceRows];
    updatedRows[index][field] = value;

    if (field === 'service') {
      const partnerId = updatedRows[index].partner.value;
      setSelectedServices((prev) => {
        const updatedSelectedServices = { ...prev };
        updatedSelectedServices[partnerId] = [
          ...(updatedSelectedServices[partnerId] || []),
          value.value,
        ];
        return updatedSelectedServices;
      });
    }

    setServiceRows(updatedRows);
  };

  useEffect(() => {
    async function loadPartners(): Promise<void> {
      const { status, data } = await httpGet('social-action/partners');
      if (status === 200) {
        const stations = data.map((station: any) => ({
          value: station.id,
          label: station.partner_name,
        }));
        setPartners(stations);
      }
    }

    loadPartners();
  }, [httpGet]);

  const loadServicesByPartner = async (partnerId: string, index: number): Promise<void> => {
    const { status, data } = await httpGet(`social-action/partners-services/${partnerId}/services`);
    if (status === 200) {
      const servicesData = data.map((service: any) => ({
        value: service.id,
        label: service.service_name,
      }));

      const filteredServices = servicesData.filter(
        (service: { value: string }) => !selectedServices[partnerId]?.includes(service.value),
      );

      const updatedRows = [...serviceRows];
      updatedRows[index].services = filteredServices;
      setServiceRows(updatedRows);
    }
  };

  const handleRegionChange = (selectedRegion: string) => {
    setSelectedMunicipio(null);
    setFilteredMunicipios([]);
    setIsBairroDisabled(true);
    setBairro('');

    const municipiosFiltrados = municipiosRJ.filter((municipio) => municipio.region === selectedRegion);
    setFilteredMunicipios(municipiosFiltrados);
  };

  const handleRemoveServiceRow = (index: number) => {
    const updatedRows = serviceRows.filter((_, rowIndex) => rowIndex !== index);

    const partnerId = serviceRows[index].partner.value;
    const serviceId = serviceRows[index].service.value;

    setSelectedServices((prev) => {
      const updatedSelectedServices = { ...prev };
      if (updatedSelectedServices[partnerId]) {
        updatedSelectedServices[partnerId] = updatedSelectedServices[partnerId].filter(
          (id) => id !== serviceId,
        );
      }
      return updatedSelectedServices;
    });

    setServiceRows(updatedRows);
  };

  const handleMunicipioChange = (selectedOption: any) => {
    setSelectedMunicipio(selectedOption.value);
    if (selectedOption.value === 'Rio de Janeiro') {
      setBairroOptions(bairrosRJ);
      setIsBairroDisabled(false);
      setBairro('');
    } else {
      setBairro('Fora da Capital');
      setIsBairroDisabled(true);
    }
  };

  const handleSubmit = useCallback(async (data: any) => {
    try {
      console.log('dandlesubmit', data)
      setBtnLoading(true);

      const bairroValue = selectedMunicipio === 'Rio de Janeiro' ? data.bairro?.value || '' : 'Fora da Capital';

      const _socialAction = {
        ...data,
        regiao: data.regiao.value,
        municipio: data.municipio.value,
        bairro: bairroValue,
        services: serviceRows.map((row: IServiceRow) => ({
          partner_id: row.partner.value,
          service_id: row.service.value,
          quantity: row.quantity,
        })),
      };

      const schema = Yup.object().shape({
        services: Yup.array().of(
          Yup.object().shape({
            quantity: Yup.number()
              .positive('A quantidade deve ser um número positivo')
              .required('A quantidade é obrigatória'),
          }),
        ),
      });

      formRef.current?.setErrors({});
      await schema.validate(_socialAction, { abortEarly: false });

      const { status } = await httpPut(`social-action/edit/${id}`, _socialAction);

      setBtnLoading(false);

      if (status === 200) {
        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        history.push('/social-action/list');
      } else {
        addToast({ title: 'Não foi possível gravar!', type: 'error' });
      }
    } catch (err) {
      setBtnLoading(false);
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      } else {
        addToast({ title: 'Erro ao salvar a ação social!', type: 'error' });
      }
    }
  }, [addToast, history, httpPut, id, selectedMunicipio, serviceRows]);

  const breadcrumbs = {
    icon: EventNoteIcon,
    links: [{ path: '', title: 'Ação Social' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <Grid container spacing={1}>
              <WrapperLabel label="Data da ação" xs={12} md={6} sm={6}>
                <Input
                  name="data_acao"
                  type="date"
                />
              </WrapperLabel>
              <WrapperLabel label="Região" xs={12} md={6} sm={6}>
                <Select
                  name="regiao"
                  options={regioesRJ}
                  onChange={(selectedOption: any) => handleRegionChange(selectedOption.value)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      boxShadow: 'none',
                      '&:hover': { border: 'none' },
                      minHeight: '2rem',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 7000,
                      position: 'absolute',
                    }),
                    container: (provided) => ({
                      ...provided,
                      zIndex: 7000,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      zIndex: 7000,
                    }),
                  }}
                  menuPosition="fixed"
                  menuPlacement="auto"
                />
              </WrapperLabel>
              <WrapperLabel label="Município" xs={12} md={6} sm={6}>
                <Select
                  name="municipio"
                  options={filteredMunicipios}
                  value={selectedMunicipio ? { value: selectedMunicipio, label: selectedMunicipio } : null}
                  onChange={handleMunicipioChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      boxShadow: 'none',
                      '&:hover': { border: 'none' },
                      minHeight: '2rem',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 6000,
                      position: 'absolute',
                    }),
                    container: (provided) => ({
                      ...provided,
                      zIndex: 6000,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      zIndex: 6000,
                    }),
                  }}
                  menuPosition="fixed"
                  menuPlacement="auto"
                />
              </WrapperLabel>
              <WrapperLabel label="Bairro" xs={12} md={6} sm={6}>
                <Select
                  name="bairro"
                  options={bairroOptions}
                  value={bairro ? { value: bairro, label: bairro } : null}
                  onChange={(e: any) => setBairro(e.value)}
                  isDisabled={isBairroDisabled || !selectedMunicipio}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      boxShadow: 'none',
                      '&:hover': { border: 'none' },
                      minHeight: '2rem',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 6000,
                      position: 'absolute',
                    }),
                    container: (provided) => ({
                      ...provided,
                      zIndex: 6000,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      zIndex: 6000,
                    }),
                  }}
                  menuPosition="fixed"
                  menuPlacement="auto"
                />
              </WrapperLabel>
            </Grid>
          </Content>
          <div style={{ marginTop: '1rem' }} />
          <Content>
            <Grid
              container
              spacing={1}
              style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center',
              }}
            >
              {serviceRows.map((row, index) => (
                <React.Fragment key={index}>
                  <WrapperLabel label="Parceiro" xs={10} md={4} sm={5}>
                    <Select
                      name={`services[${index}].partner_id`}
                      options={partners}
                      defaultValue={row.partner}
                      onChange={(selectedOption: any) => {
                        if (selectedOption) {
                          handleServiceRowChange(index, 'partner', selectedOption);
                          loadServicesByPartner(selectedOption.value, index);
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: 'none',
                          boxShadow: 'none',
                          '&:hover': { border: 'none' },
                          minHeight: '2rem',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 2000 - index,
                          position: 'absolute',
                        }),
                        container: (provided) => ({
                          ...provided,
                          zIndex: 2000 - index,
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          zIndex: 2000 - index,
                        }),
                      }}
                      menuPosition="fixed"
                      menuPlacement="auto"
                    />
                  </WrapperLabel>
                  <WrapperLabel label="Serviço" xs={10} md={4} sm={5}>
                    <Select
                      name={`services[${index}].service_id`}
                      options={row.services}
                      defaultValue={row.service}
                      onChange={(selectedOption: any) => {
                        if (selectedOption) {
                          handleServiceRowChange(index, 'service', selectedOption);
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: 'none',
                          boxShadow: 'none',
                          '&:hover': { border: 'none' },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 2000 - index,
                          position: 'absolute',
                        }),
                        container: (provided) => ({
                          ...provided,
                          zIndex: 2000 - index,
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          zIndex: 2000 - index,
                        }),
                      }}
                      menuPosition="fixed"
                      menuPlacement="auto"
                    />
                  </WrapperLabel>
                  <WrapperLabel label="Quantidade" xs={4} sm={3}>
                    <Input
                      name={`services[${index}].quantity`}
                      value={row.quantity}
                      onChange={(e: any) => handleServiceRowChange(index, 'quantity', e.target.value)}
                    />
                    <ButtonIcon title="Apagar" onClick={() => handleRemoveServiceRow(index)}>
                      <FaRegTrashAlt />
                    </ButtonIcon>
                  </WrapperLabel>
                </React.Fragment>
              ))}
              <Grid
                container
                style={{
                  justifyContent: 'center', alignItems: 'center', marginTop: '2.5rem', marginBottom: '2rem',
                }}
              >
                <ButtonIcon title="Novo Serviço" onClick={addNewServiceRow}>
                  <PlusIcon />
                </ButtonIcon>
              </Grid>
            </Grid>
            <GridButtons>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => { history.push('/social-action/list'); }}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button
                  type="submit"
                  isLoading={btnLoading}
                >
                  Enviar
                </Button>
              </WrapperBtnSubmit>
            </GridButtons>
          </Content>
        </UForm>
      </Container>
    </Layout>
  );
};

export default Profile;