/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from 'react';
import { Form as UForm } from '@unform/web';
import Layout from 'components/Layout';
import { useHttp } from 'hooks/http';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import {
  Button, ButtonIcon, Input, WrapperLabel,
} from 'components/Forms';
import { useHistory } from 'react-router-dom';
import getValidationErrors from 'utils/getValidationsErros';
import { useToast } from 'hooks/toast';
import { InfoIcon } from 'styles/icons';
import { IoMdBusiness } from 'react-icons/io';
import {
  Container, Content, GridButtons, WrapperBtnCancel, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';

const Partner: React.FC = () => {
  const history = useHistory();
  const { httpPost, loading } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data : any) => {
    try {
      setBtnLoading(true);
      // eslint-disable-next-line no-underscore-dangle
      const _partner = {
        ...data,
      };
      const schema = Yup.object().shape({
        partner_name: Yup.string().required('Nome do parceiro é obrigatório'),
      });

      await schema.validate(_partner, { abortEarly: false });

      const { status } = await httpPost('social-action/partners', {
        ..._partner,
      });
      setBtnLoading(false);
      if (status === 201) {
        addToast({ title: 'Salvo com suceso!', type: 'success' });
        formRef.current?.reset();
        history.push('/social-action/partners/create');
      } else {
        addToast({ title: 'Não foi possível' });
        formRef.current?.reset();
        history.push('/social-action/partners/create');
      }
    } catch (error) {
      setBtnLoading(false);
      if (error instanceof Yup.ValidationError) {
        const erros = getValidationErrors(error);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, history, httpPost]);

  const breadcrumbs = {
    icon: IoMdBusiness,
    links: [{ path: '', title: 'Parceiros' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Novo Parceiro" xs={12}>
                <Input
                  name="partner_name"
                  style={{ textTransform: 'uppercase' }}
                />
              </WrapperLabel>
            </Grid>
            <GridButtons>
              <WrapperBtnInfo>
                <ButtonIcon
                  title="Instruções"
                  onClick={() => labelRef.current?.click()}
                >
                  <InfoIcon />
                </ButtonIcon>
              </WrapperBtnInfo>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => { history.push('/'); }}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button
                  type="submit"
                  isLoading={btnLoading}
                >
                  Enviar
                </Button>
              </WrapperBtnSubmit>
            </GridButtons>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default Partner;
