import React from 'react';
import ModalFactory from 'react-modal-promise';
import { distanceDateTime } from 'utils';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { ListIcon } from 'styles/icons';
import { useHistory } from 'react-router';
import { FaEdit } from 'react-icons/fa';
import { Container, WrapperMenu } from './styles';
import Flex, { Grid } from '../../../components/Flex';
import {
  Menu, MenuItem, DotButton, ItemIcon,
} from '../../../components/CustomMenu';

interface ISocialAction {
  id: string;
  user: string
  municipio: string;
  bairro: string;
  regiao: string;
  data_acao: string;
  partner_total: number;
  service_total: number;
  created_at: string;
  total_quantity: number;
  server_time: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

// eslint-disable-next-line arrow-body-style
const Card: React.FC<{ socialAction: ISocialAction }> = ({ socialAction }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();

  return (
    <Container>
      <WrapperMenu>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <>
              <DotButton {...bindTrigger(popupState)} />
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => { history.push(`/social-action/detail/${socialAction.id}`); }}
                  style={{ width: 150 }}
                >
                  <ItemIcon icon={<FaEdit />} title="Detalhe" />
                </MenuItem>
                <MenuItem
                  onClick={() => { history.push(`/social-action/edit/${socialAction.id}`); }}
                  style={{ width: 150 }}
                >
                  <ItemIcon icon={<ListIcon />} title="Editar" />
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </WrapperMenu>
      <Grid container spacing={1}>
        <Flex title="Data da Ação" xs={6} sm={6} md={4}>
          {socialAction.data_acao ? formatDate(socialAction.data_acao) : 'Não identificado'}
        </Flex>

        <Flex title="Região" xs={6} sm={6} md={4}>
          {socialAction.regiao || 'Não identificado'}
        </Flex>

        <Flex title="Total de Parceiros" xs={6} sm={6} md={4}>
          {socialAction.partner_total ? Number(socialAction.partner_total) : 'Não identificado'}
        </Flex>

        <Flex title="Criado Por" xs={6} sm={6} md={4}>
          {socialAction.user || 'Não cadastrado'}
        </Flex>

        <Flex title="Município" xs={6} sm={6} md={4}>
          {socialAction.municipio || 'Não identificado'}
        </Flex>
        <Flex title="Total de Serviços" xs={6} sm={6} md={4}>
          {socialAction.total_quantity ? Number(socialAction.total_quantity) : 'Não identificado'}
        </Flex>
        <Flex title="Criado há" xs={6} sm={6} md={4}>
          {socialAction.created_at && socialAction.server_time
            ? distanceDateTime(socialAction.created_at, socialAction.server_time, false)
            : 'Tempo não disponível'}
        </Flex>
        <Flex title="Bairro" xs={6} sm={6} md={4}>
          {socialAction.bairro || 'Fora da Capital'}
        </Flex>
      </Grid>
      <ModalFactory />
    </Container>
  );
};

export default Card;
