import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';

import pt from 'date-fns/locale/pt-BR'; // eslint-disable-line
import { parseISO } from 'date-fns'; // eslint-disable-line
import { useField } from '@unform/core';

import 'react-datepicker/dist/react-datepicker.css';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

registerLocale('pt-BR', pt);

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
}

const DatePicker: React.FC<Props> = ({ name, ...rest }) => {
  const datepickerRef = useRef(null);
  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);

  const [date, setDate] = useState<Date | null>(defaultValue ? parseISO(defaultValue) : null);

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,

      getValue: (ref) => ref.props.selected,
      setValue: (ref, value) => {
        if (value) { ref.setSelected(parseISO(value as string)); }
      },
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container isErrored={!!error} isFocused={isFocused}>

      <ReactDatePicker
        ref={datepickerRef}
        onFocus={handleFocused}
        onBlur={handleInputBlur}
        selected={date}
        onChange={(value) => {
          value instanceof Date
            ? setDate(value)
            : setDate(null);
        }}
        dateFormat="dd/MM/yyyy"
        locale="pt-BR"
        {...rest}
      />

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
    </Container>
  );
};

export default DatePicker;
