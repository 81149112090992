const regioesRJ = [
  { value: 'Região Metropolitana', label: 'Região Metropolitana' },
  { value: 'Região da Costa Verde', label: 'Região da Costa Verde' },
  { value: 'Região das Baixadas Litorâneas', label: 'Região das Baixadas Litorâneas' },
  { value: 'Região Centro-Sul Fluminense', label: 'Região Centro-Sul Fluminense' },
  { value: 'Região Médio Paraíba', label: 'Região Médio Paraíba' },
  { value: 'Baixada Fluminense', label: 'Baixada Fluminense' },
  { value: 'Leste Fluminense', label: 'Leste Fluminense' },
  { value: 'Região Noroeste Fluminense', label: 'Região Noroeste Fluminense' },
  { value: 'Região Norte Fluminense', label: 'Região Norte Fluminense' },
  { value: 'Região Serrana', label: 'Região Serrana' },
];

const municipiosRJ = [
  { value: 'Areal', label: 'Areal', region: 'Região Centro-Sul Fluminense' },
  { value: 'Comendador Levy Gasparian', label: 'Comendador Levy Gasparian', region: 'Região Centro-Sul Fluminense' },
  { value: 'Engenheiro Paulo de Frontin', label: 'Engenheiro Paulo de Frontin', region: 'Região Centro-Sul Fluminense' },
  { value: 'Mendes', label: 'Mendes', region: 'Região Centro-Sul Fluminense' },
  { value: 'Miguel Pereira', label: 'Miguel Pereira', region: 'Região Centro-Sul Fluminense' },
  { value: 'Paraíba do Sul', label: 'Paraíba do Sul', region: 'Região Centro-Sul Fluminense' },
  { value: 'Paty do Alferes', label: 'Paty do Alferes', region: 'Região Centro-Sul Fluminense' },
  { value: 'Sapucaia', label: 'Sapucaia', region: 'Região Centro-Sul Fluminense' },
  { value: 'Três Rios', label: 'Três Rios', region: 'Região Centro-Sul Fluminense' },
  { value: 'Vassouras', label: 'Vassouras', region: 'Região Centro-Sul Fluminense' },
  { value: 'Angra Dos Reis', label: 'Angra Dos Reis', region: 'Região da Costa Verde' },
  { value: 'Itaguaí', label: 'Itaguaí', region: 'Região da Costa Verde' },
  { value: 'Mangaratiba', label: 'Mangaratiba', region: 'Região da Costa Verde' },
  { value: 'Paraty', label: 'Paraty', region: 'Região da Costa Verde' },
  { value: 'Araruama', label: 'Araruama', region: 'Região das Baixadas Litorâneas' },
  { value: 'Armação Dos Búzios', label: 'Armação Dos Búzios', region: 'Região das Baixadas Litorâneas' },
  { value: 'Arraial do Cabo', label: 'Arraial do Cabo', region: 'Região das Baixadas Litorâneas' },
  { value: 'Cabo Frio', label: 'Cabo Frio', region: 'Região das Baixadas Litorâneas' },
  { value: 'Casimiro de Abreu', label: 'Casimiro de Abreu', region: 'Região das Baixadas Litorâneas' },
  { value: 'Iguaba Grande', label: 'Iguaba Grande', region: 'Região das Baixadas Litorâneas' },
  { value: 'Rio Das Ostras', label: 'Rio Das Ostras', region: 'Região das Baixadas Litorâneas' },
  { value: 'São Pedro da Aldeia', label: 'São Pedro da Aldeia', region: 'Região das Baixadas Litorâneas' },
  { value: 'Saquarema', label: 'Saquarema', region: 'Região das Baixadas Litorâneas' },
  { value: 'Silva Jardim', label: 'Silva Jardim', region: 'Região das Baixadas Litorâneas' },
  { value: 'Barra do Piraí', label: 'Barra do Piraí', region: 'Região Médio Paraíba' },
  { value: 'Barra Mansa', label: 'Barra Mansa', region: 'Região Médio Paraíba' },
  { value: 'Itatiaia', label: 'Itatiaia', region: 'Região Médio Paraíba' },
  { value: 'Pinheiral', label: 'Pinheiral', region: 'Região Médio Paraíba' },
  { value: 'Piraí', label: 'Piraí', region: 'Região Médio Paraíba' },
  { value: 'Porto Real', label: 'Porto Real', region: 'Região Médio Paraíba' },
  { value: 'Quatis', label: 'Quatis', region: 'Região Médio Paraíba' },
  { value: 'Resende', label: 'Resende', region: 'Região Médio Paraíba' },
  { value: 'Rio Claro', label: 'Rio Claro', region: 'Região Médio Paraíba' },
  { value: 'Rio Das Flores', label: 'Rio Das Flores', region: 'Região Médio Paraíba' },
  { value: 'Valença', label: 'Valença', region: 'Região Médio Paraíba' },
  { value: 'Volta Redonda', label: 'Volta Redonda', region: 'Região Médio Paraíba' },
  { value: 'Rio de Janeiro', label: 'Rio de Janeiro', region: 'Região Metropolitana' },
  { value: 'Belford Roxo', label: 'Belford Roxo', region: 'Baixada Fluminense' },
  { value: 'Duque de Caxias', label: 'Duque de Caxias', region: 'Baixada Fluminense' },
  { value: 'Japeri', label: 'Japeri', region: 'Baixada Fluminense' },
  { value: 'Magé', label: 'Magé', region: 'Baixada Fluminense' },
  { value: 'Mesquita', label: 'Mesquita', region: 'Baixada Fluminense' },
  { value: 'Nilópolis', label: 'Nilópolis', region: 'Baixada Fluminense' },
  { value: 'Nova Iguaçu', label: 'Nova Iguaçu', region: 'Baixada Fluminense' },
  { value: 'Paracambi', label: 'Paracambi', region: 'Baixada Fluminense' },
  { value: 'Queimados', label: 'Queimados', region: 'Baixada Fluminense' },
  { value: 'São João de Meriti', label: 'São João de Meriti', region: 'Baixada Fluminense' },
  { value: 'Seropédica', label: 'Seropédica', region: 'Baixada Fluminense' },
  { value: 'Itaboraí', label: 'Itaboraí', region: 'Leste Fluminense' },
  { value: 'Maricá', label: 'Maricá', region: 'Leste Fluminense' },
  { value: 'Niterói', label: 'Niterói', region: 'Leste Fluminense' },
  { value: 'Rio Bonito', label: 'Rio Bonito', region: 'Leste Fluminense' },
  { value: 'São Gonçalo', label: 'São Gonçalo', region: 'Leste Fluminense' },
  { value: 'Tanguá', label: 'Tanguá', region: 'Leste Fluminense' },
  { value: 'Aperibé', label: 'Aperibé', region: 'Região Noroeste Fluminense' },
  { value: 'Bom Jesus do Itabapoana', label: 'Bom Jesus do Itabapoana', region: 'Região Noroeste Fluminense' },
  { value: 'Cambuci', label: 'Cambuci', region: 'Região Noroeste Fluminense' },
  { value: 'Italva', label: 'Italva', region: 'Região Noroeste Fluminense' },
  { value: 'Itaocara', label: 'Itaocara', region: 'Região Noroeste Fluminense' },
  { value: 'Itaperuna', label: 'Itaperuna', region: 'Região Noroeste Fluminense' },
  { value: 'Laje do Muriaé', label: 'Laje do Muriaé', region: 'Região Noroeste Fluminense' },
  { value: 'Miracema', label: 'Miracema', region: 'Região Noroeste Fluminense' },
  { value: 'Natividade', label: 'Natividade', region: 'Região Noroeste Fluminense' },
  { value: 'Porciúncula', label: 'Porciúncula', region: 'Região Noroeste Fluminense' },
  { value: 'Santo Antônio de Pádua', label: 'Santo Antônio de Pádua', region: 'Região Noroeste Fluminense' },
  { value: 'São José de Ubá', label: 'São José de Ubá', region: 'Região Noroeste Fluminense' },
  { value: 'Varre-sai', label: 'Varre-sai', region: 'Região Noroeste Fluminense' },
  { value: 'Campos Dos Goytacazes', label: 'Campos Dos Goytacazes', region: 'Região Norte Fluminense' },
  { value: 'Carapebus', label: 'Carapebus', region: 'Região Norte Fluminense' },
  { value: 'Cardoso Moreira', label: 'Cardoso Moreira', region: 'Região Norte Fluminense' },
  { value: 'Conceição de Macabu', label: 'Conceição de Macabu', region: 'Região Norte Fluminense' },
  { value: 'Macaé', label: 'Macaé', region: 'Região Norte Fluminense' },
  { value: 'Quissamã', label: 'Quissamã', region: 'Região Norte Fluminense' },
  { value: 'São Fidélis', label: 'São Fidélis', region: 'Região Norte Fluminense' },
  { value: 'São Francisco de Itabapoana', label: 'São Francisco de Itabapoana', region: 'Região Norte Fluminense' },
  { value: 'São João da Barra', label: 'São João da Barra', region: 'Região Norte Fluminense' },
  { value: 'Bom Jardim', label: 'Bom Jardim', region: 'Região Serrana' },
  { value: 'Cantagalo', label: 'Cantagalo', region: 'Região Serrana' },
  { value: 'Cachoeiras de Macacu', label: 'Cachoeiras de Macacu', region: 'Região Serrana' },
  { value: 'Carmo', label: 'Carmo', region: 'Região Serrana' },
  { value: 'Cordeiro', label: 'Cordeiro', region: 'Região Serrana' },
  { value: 'Duas Barras', label: 'Duas Barras', region: 'Região Serrana' },
  { value: 'Guapimirim', label: 'Guapimirim', region: 'Região Serrana' },
  { value: 'Macuco', label: 'Macuco', region: 'Região Serrana' },
  { value: 'Nova Friburgo', label: 'Nova Friburgo', region: 'Região Serrana' },
  { value: 'Petrópolis', label: 'Petrópolis', region: 'Região Serrana' },
  { value: 'Santa Maria Madalena', label: 'Santa Maria Madalena', region: 'Região Serrana' },
  { value: 'São José do Vale do Rio Preto', label: 'São José do Vale do Rio Preto', region: 'Região Serrana' },
  { value: 'São Sebastião do Alto', label: 'São Sebastião do Alto', region: 'Região Serrana' },
  { value: 'Sumidouro', label: 'Sumidouro', region: 'Região Serrana' },
  { value: 'Teresópolis', label: 'Teresópolis', region: 'Região Serrana' },
  { value: 'Trajano de Morais', label: 'Trajano de Morais', region: 'Região Serrana' },
];

const bairrosRJ = [
  { value: 'Barra da Tijuca', label: 'Barra da Tijuca' },
  { value: 'Barra Olímpica', label: 'Barra Olímpica' },
  { value: 'Camorim', label: 'Camorim' },
  { value: 'Grumari', label: 'Grumari' },
  { value: 'Itanhangá', label: 'Itanhangá' },
  { value: 'Joá', label: 'Joá' },
  { value: 'Recreio dos Bandeirantes', label: 'Recreio dos Bandeirantes' },
  { value: 'Vargem Grande', label: 'Vargem Grande' },
  { value: 'Vargem Pequena', label: 'Vargem Pequena' },
  { value: 'Benfica', label: 'Benfica' },
  { value: 'Caju', label: 'Caju' },
  { value: 'Catumbi', label: 'Catumbi' },
  { value: 'Centro', label: 'Centro' },
  { value: 'Cidade Nova', label: 'Cidade Nova' },
  { value: 'Estácio', label: 'Estácio' },
  { value: 'Gamboa', label: 'Gamboa' },
  { value: 'Glória', label: 'Glória' },
  { value: 'Lapa', label: 'Lapa' },
  { value: 'Mangueira', label: 'Mangueira' },
  { value: 'Rio Comprido', label: 'Rio Comprido' },
  { value: 'Santa Teresa', label: 'Santa Teresa' },
  { value: 'Santo Cristo', label: 'Santo Cristo' },
  { value: 'São Cristóvão', label: 'São Cristóvão' },
  { value: 'Saúde', label: 'Saúde' },
  { value: 'Vasco da Gama', label: 'Vasco da Gama' },
  { value: 'Bangu', label: 'Bangu' },
  { value: 'Campo dos Afonsos', label: 'Campo dos Afonsos' },
  { value: 'Deodoro', label: 'Deodoro' },
  { value: 'Gericinó', label: 'Gericinó' },
  { value: 'Jabour', label: 'Jabour' },
  { value: 'Jardim Sulacap', label: 'Jardim Sulacap' },
  { value: 'Magalhães Bastos', label: 'Magalhães Bastos' },
  { value: 'Padre Miguel', label: 'Padre Miguel' },
  { value: 'Realengo', label: 'Realengo' },
  { value: 'Senador Camará', label: 'Senador Camará' },
  { value: 'Vila Kennedy', label: 'Vila Kennedy' },
  { value: 'Vila Militar', label: 'Vila Militar' },
  { value: 'Alto da Boa Vista', label: 'Alto da Boa Vista' },
  { value: 'Andaraí', label: 'Andaraí' },
  { value: 'Grajaú', label: 'Grajaú' },
  { value: 'Maracanã', label: 'Maracanã' },
  { value: 'Praça da Bandeira', label: 'Praça da Bandeira' },
  { value: 'Tijuca', label: 'Tijuca' },
  { value: 'Vila Isabel', label: 'Vila Isabel' },
  { value: 'Bancários', label: 'Bancários' },
  { value: 'Cacuia', label: 'Cacuia' },
  { value: 'Cidade Universitária', label: 'Cidade Universitária' },
  { value: 'Cocotá', label: 'Cocotá' },
  { value: 'Freguesia (Ilha do Governador)', label: 'Freguesia (Ilha do Governador)' },
  { value: 'Galeão', label: 'Galeão' },
  { value: 'Jardim Carioca', label: 'Jardim Carioca' },
  { value: 'Jardim Guanabara', label: 'Jardim Guanabara' },
  { value: 'Moneró', label: 'Moneró' },
  { value: 'Paquetá', label: 'Paquetá' },
  { value: 'Pitangueiras', label: 'Pitangueiras' },
  { value: 'Portuguesa', label: 'Portuguesa' },
  { value: 'Praia da Bandeira', label: 'Praia da Bandeira' },
  { value: 'Ribeira', label: 'Ribeira' },
  { value: 'Tauá', label: 'Tauá' },
  { value: 'Zumbi', label: 'Zumbi' },
  { value: 'Anil', label: 'Anil' },
  { value: 'Cidade de Deus', label: 'Cidade de Deus' },
  { value: 'Curicica', label: 'Curicica' },
  { value: 'Freguesia (Jacarepaguá)', label: 'Freguesia (Jacarepaguá)' },
  { value: 'Gardênia Azul', label: 'Gardênia Azul' },
  { value: 'Jacarepaguá', label: 'Jacarepaguá' },
  { value: 'Pechincha', label: 'Pechincha' },
  { value: 'Praça Seca', label: 'Praça Seca' },
  { value: 'Tanque', label: 'Tanque' },
  { value: 'Taquara', label: 'Taquara' },
  { value: 'Vila Valqueire', label: 'Vila Valqueire' },
  { value: 'Abolição', label: 'Abolição' },
  { value: 'Acari', label: 'Acari' },
  { value: 'Água Santa', label: 'Água Santa' },
  { value: 'Anchieta', label: 'Anchieta' },
  { value: 'Barros Filho', label: 'Barros Filho' },
  { value: 'Bento Ribeiro', label: 'Bento Ribeiro' },
  { value: 'Bonsucesso', label: 'Bonsucesso' },
  { value: 'Brás de Pina', label: 'Brás de Pina' },
  { value: 'Cachambi', label: 'Cachambi' },
  { value: 'Campinho', label: 'Campinho' },
  { value: 'Cascadura', label: 'Cascadura' },
  { value: 'Cavalcanti', label: 'Cavalcanti' },
  { value: 'Coelho Neto', label: 'Coelho Neto' },
  { value: 'Colégio', label: 'Colégio' },
  { value: 'Complexo do Alemão', label: 'Complexo do Alemão' },
  { value: 'Cordovil', label: 'Cordovil' },
  { value: 'Costa Barros', label: 'Costa Barros' },
  { value: 'Del Castilho', label: 'Del Castilho' },
  { value: 'Encantado', label: 'Encantado' },
  { value: 'Engenheiro Leal', label: 'Engenheiro Leal' },
  { value: 'Engenho da Rainha', label: 'Engenho da Rainha' },
  { value: 'Engenho de Dentro', label: 'Engenho de Dentro' },
  { value: 'Engenho Novo', label: 'Engenho Novo' },
  { value: 'Guadalupe', label: 'Guadalupe' },
  { value: 'Higienópolis', label: 'Higienópolis' },
  { value: 'Honório Gurgel', label: 'Honório Gurgel' },
  { value: 'Inhaúma', label: 'Inhaúma' },
  { value: 'Irajá', label: 'Irajá' },
  { value: 'Jacaré', label: 'Jacaré' },
  { value: 'Jacarezinho', label: 'Jacarezinho' },
  { value: 'Jardim América', label: 'Jardim América' },
  { value: 'Lins de Vasconcelos', label: 'Lins de Vasconcelos' },
  { value: 'Madureira', label: 'Madureira' },
  { value: 'Manguinhos', label: 'Manguinhos' },
  { value: 'Maré', label: 'Maré' },
  { value: 'Marechal Hermes', label: 'Marechal Hermes' },
  { value: 'Maria da Graça', label: 'Maria da Graça' },
  { value: 'Méier', label: 'Méier' },
  { value: 'Olaria', label: 'Olaria' },
  { value: 'Oswaldo Cruz', label: 'Oswaldo Cruz' },
  { value: 'Parada de Lucas', label: 'Parada de Lucas' },
  { value: 'Parque Anchieta', label: 'Parque Anchieta' },
  { value: 'Parque Colúmbia', label: 'Parque Colúmbia' },
  { value: 'Pavuna', label: 'Pavuna' },
  { value: 'Penha', label: 'Penha' },
  { value: 'Penha Circular', label: 'Penha Circular' },
  { value: 'Piedade', label: 'Piedade' },
  { value: 'Pilares', label: 'Pilares' },
  { value: 'Quintino Bocaiuva', label: 'Quintino Bocaiuva' },
  { value: 'Ramos', label: 'Ramos' },
  { value: 'Riachuelo', label: 'Riachuelo' },
  { value: 'Ricardo de Albuquerque', label: 'Ricardo de Albuquerque' },
  { value: 'Rocha', label: 'Rocha' },
  { value: 'Rocha Miranda', label: 'Rocha Miranda' },
  { value: 'Sampaio', label: 'Sampaio' },
  { value: 'São Francisco Xavier', label: 'São Francisco Xavier' },
  { value: 'Todos os Santos', label: 'Todos os Santos' },
  { value: 'Tomás Coelho', label: 'Tomás Coelho' },
  { value: 'Turiaçu', label: 'Turiaçu' },
  { value: 'Vaz Lobo', label: 'Vaz Lobo' },
  { value: 'Vicente de Carvalho', label: 'Vicente de Carvalho' },
  { value: 'Vigário Geral', label: 'Vigário Geral' },
  { value: 'Vila da Penha', label: 'Vila da Penha' },
  { value: 'Vila Kosmos', label: 'Vila Kosmos' },
  { value: 'Vista Alegre', label: 'Vista Alegre' },
  { value: 'Barra de Guaratiba', label: 'Barra de Guaratiba' },
  { value: 'Campo Grande', label: 'Campo Grande' },
  { value: 'Cosmos', label: 'Cosmos' },
  { value: 'Guaratiba', label: 'Guaratiba' },
  { value: 'Ilha de Guaratiba', label: 'Ilha de Guaratiba' },
  { value: 'Inhoaíba', label: 'Inhoaíba' },
  { value: 'Paciência', label: 'Paciência' },
  { value: 'Pedra de Guaratiba', label: 'Pedra de Guaratiba' },
  { value: 'Santa Cruz', label: 'Santa Cruz' },
  { value: 'Santíssimo', label: 'Santíssimo' },
  { value: 'Senador Vasconcelos', label: 'Senador Vasconcelos' },
  { value: 'Sepetiba', label: 'Sepetiba' },
  { value: 'Botafogo', label: 'Botafogo' },
  { value: 'Catete', label: 'Catete' },
  { value: 'Copacabana', label: 'Copacabana' },
  { value: 'Cosme Velho', label: 'Cosme Velho' },
  { value: 'Flamengo', label: 'Flamengo' },
  { value: 'Gávea', label: 'Gávea' },
  { value: 'Humaitá', label: 'Humaitá' },
  { value: 'Ipanema', label: 'Ipanema' },
  { value: 'Jardim Botânico', label: 'Jardim Botânico' },
  { value: 'Lagoa', label: 'Lagoa' },
  { value: 'Laranjeiras', label: 'Laranjeiras' },
  { value: 'Leblon', label: 'Leblon' },
  { value: 'Leme', label: 'Leme' },
  { value: 'Rocinha', label: 'Rocinha' },
  { value: 'São Conrado', label: 'São Conrado' },
  { value: 'Urca', label: 'Urca' },
  { value: 'Vidigal', label: 'Vidigal' },
];

export { regioesRJ, municipiosRJ, bairrosRJ };
