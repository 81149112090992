import React from 'react';
import ModalFactory from 'react-modal-promise';
import { distanceDateTime } from 'utils';

import IUser from 'interfaces/user';
import { Container } from './styles';
import Flex, { Grid } from '../../../../components/Flex';

interface IService {
    service_name: string
}

interface IPartner {
    partner_name: string
}

interface IPartnerService {
  id: string;
  user: IUser;
  service_rj: IService;
  partner: IPartner;
  created_at: string;
  server_time: string;
}

// eslint-disable-next-line arrow-body-style
const Card: React.FC<{ partnerService: IPartnerService }> = ({ partnerService }) => {
  return (
    <Container>
      <Grid container spacing={1}>
        <Flex title="Responsável" xs={6} sm={6} md={2}>
          {partnerService.user?.name || 'Não cadastrado'}
        </Flex>
        <Flex title="Parceiro" xs={6} sm={6} md={4}>
          {partnerService.partner?.partner_name}
        </Flex>
        <Flex title="Serviço" xs={6} sm={6} md={4}>
          {partnerService.service_rj?.service_name}
        </Flex>
        <Flex title="Criado há" xs={6} sm={6} md={2}>
          {distanceDateTime(partnerService.created_at, partnerService.server_time, false)}
        </Flex>
      </Grid>
      <ModalFactory />
    </Container>
  );
};

export default Card;
