import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: var(--shadow);
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  position: relative;

  + div{
    margin-top: 8px;
  }

  .rotate{
    margin-left: 4px;
    font-size: 18px;
    transform: rotate(10deg);
  }
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperMenu = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
`;

export const WrapperRef = styled.span`
  height: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  /* > svg{
    flex-shrink: 0;
    font-size: 24px;
    margin-right: 8px;
  } */

`;
