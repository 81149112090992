import { addHours, differenceInHours } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import ModalFactory from 'react-modal-promise';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Accordion from '../../../../components/Accordion';
import Flex, { Grid } from '../../../../components/Flex';
import Layout from '../../../../components/Layout';
import { ModalOption } from '../../../../components/ModalOption';
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';
import { ListIcon, TrashIcon } from '../../../../styles/icons';
import { formatDate } from '../../../../utils';
import {
  Container, ContentWapper, Title, WrapperAccordion, WrapperHeader,
} from './styles';
import { useAuth } from 'hooks/auth';

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { httpGet, httpDelete, loading } = useHttp();
  const { addToast } = useToast();
  const { iCan } = useAuth();
  const [rcol, setRcol] = useState<any>({} as any);

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const { status, data } = await httpGet(`/rcols/${id}`);
      if (status === 200) {
        setRcol(data);
      }
    }
    loadAnswer();
    }, [addToast, history, id]);  // eslint-disable-line

  const breadcrumbs = {
    icon: ListIcon,
    links: [
      { path: '/rcols', title: 'Relatórios' },
      { path: '', title: 'Detalhe do Relatório' },
    ],
  };

  let total_celebrity = null;
  let total_criminaloccurrences = null;
  let total_thirtyfour_ml = null;
  let total_arrests_means_evidence = null;
  let total_refusal = null;

  if (rcol?.sectiontwo?.length !== 0) {
    const totalCelebrity = rcol?.sectiontwo?.map((v: any) => v?.celebrities_authorities);

    total_celebrity = totalCelebrity?.reduce((t: any, i: any) => t + i);

    const totalCriminalOccurrences = rcol?.sectiontwo?.map((v: any) => v?.criminal_occurrences);

    total_criminaloccurrences = totalCriminalOccurrences?.reduce((t: any, i: any) => t + i);

    const totalThirtyfourMl = rcol?.sectiontwo?.map((v: any) => v?.thirtyfour_ml);

    total_thirtyfour_ml = totalThirtyfourMl?.reduce((t: any, i: any) => t + i);

    const totalArrestsMeansEvidence = rcol?.sectiontwo?.map((v: any) => v?.arrests_means_evidence);

    total_arrests_means_evidence = totalArrestsMeansEvidence?.reduce((t: any, i: any) => t + i);

    const totalRefusal = rcol?.sectiontwo?.map((v: any) => v?.refusal);

    total_refusal = totalRefusal?.reduce((t: any, i: any) => t + i);
  }

  const handleDateBefore = useCallback((date) => {
    if(iCan('d-rcols')){
      return true
    }
    const result = differenceInHours(addHours(new Date(), 3), new Date(date));
    if (result < 2) {
      return true;
    }

    return false;
  }, [iCan]);

  const handleDelete = useCallback(async (id) => {
    const response = await ModalOption({
      title: 'ATENÇÃO',
      content: 'Deseja excluir o relatório ?',
      buttons: 'sim_nao',
    });

    if (response) {
      const { status } = await httpDelete(`rcols/${id}`);
      if (status === 200) {
        addToast({ title: 'Excluído com sucesso', type: 'info' });
        history.push('/rcols');
      }
    }
  }, [history, addToast, httpDelete]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <WrapperHeader>
          <span>Equipe: {rcol?.team} </span>
          <b>Data da Operação: {formatDate(rcol?.operation_date?.split('T')[0])}</b>
          {handleDateBefore(rcol.created_at) && (
            <span>
              <TrashIcon
                color="red"
                onClick={() => { handleDelete(rcol?.id); }}
                cursor="pointer"
              />
            </span>
          )}
        </WrapperHeader>
        <Title>Dados da Operação:</Title>
        <Grid container spacing={1} justify="space-between">
          <Flex title="Chefe da Equipe Militar" xs={6} sm={4} md={4}>
            {rcol?.military?.name || 'não informado'}
          </Flex>
          <Flex title="Efetivo Militar" xs={6} sm={4} md={4}>
            {rcol?.segovTeamMilitar || 'não informado'}
          </Flex>
          <Flex title="Efetivo Civil" xs={6} sm={4} md={4}>
            {rcol?.segovTeamCivil || 'não informado'}
          </Flex>
          <Flex title="Viaturas Ols Utilizadas na Operação" xs={12} sm={4} md={4}>
            {rcol?.cars || 'não informado'}
          </Flex>
          <Flex title="Ag. do Detran" xs={4} sm={4} md={4}>
            {rcol?.agent_detran || 'não informado'}
          </Flex>
          <Flex title="Reboques" xs={4} sm={4} md={4}>
            {rcol?.number_trailers || 'não informado'}
          </Flex>
          <Flex title="Protocolo de Chuva" xs={4} sm={4} md={4}>
            {rcol?.protocol || 'não informado'}
          </Flex>
        </Grid>
        <Title>Quantitativo:</Title>
        <Grid container spacing={1} justify="space-between">
          <Flex title="Celebridades / Autoridades" xs={6} sm={4} md={4}>
            {total_celebrity}
          </Flex>
          <Flex title="Ocorrências Criminais Alcoolemia" xs={6} sm={4} md={4}>
            {(total_arrests_means_evidence + total_thirtyfour_ml + total_refusal) || 'não informado'}
          </Flex>
          <Flex title="Ocorrências Diversas" xs={6} sm={4} md={4}>
            {total_criminaloccurrences || 'não informado'}
          </Flex>
        </Grid>
        <Title>Operações:</Title>
        {rcol?.sectiontwo?.map((v: any, index: any) => (
          <WrapperAccordion key={v.id}>
            <Accordion title={`Operação #${index + 1}`}>
              <Grid container spacing={1} justify="space-between">
                <Flex title="End. da Operação" xs={12}>
                  {v.addressOperation || 'não informado'}
                </Flex>
                <Flex title="Outro End. Não Listado" xs={12}>
                  {v.another_not_listed || 'não informado'}
                </Flex>
                <Flex title="Cep" xs={4}>
                  {v.cep || 'não informado'}
                </Flex>
                <Flex title="Rua" xs={6}>
                  {v.street || 'não informado'}
                </Flex>
                <Flex title="Numero" xs={2}>
                  {v.number || 'não informado'}
                </Flex>
                <Flex title="Bairro" xs={4}>
                  {v.district || 'não informado'}
                </Flex>
                <Flex title="Cidade" xs={8}>
                  {v.city || 'não informado'}
                </Flex>
                <Flex title="Saída do Ponto de Encontro" xs={6} sm={4} md={4}>
                  {v.departure_meeting_point || 'não informado'}
                </Flex>
                <Flex title="Montagem da Operação" xs={6} sm={4} md={4}>
                  {v.operation_assembly || 'não informado'}
                </Flex>
                <Flex title="Primeira Abordagem" xs={6} sm={4} md={4}>
                  {v.first_approach || 'não informado'}
                </Flex>
                <Flex title="Encerramento" xs={6} sm={4} md={4}>
                  {v.closing || 'não informado'}
                </Flex>
                <Flex title="Abordagem" xs={6} sm={4} md={4}>
                  {v.approach || 'não informado'}
                </Flex>
                <Flex title="Recondutor" xs={6} sm={4} md={4}>
                  {v.reconductor}
                </Flex>
                <Flex title="Recusa" xs={6} sm={4} md={4}>
                  {v.refusal}
                </Flex>
                <Flex title="Celebridades / Autoridades" xs={6} sm={4} md={4}>
                  {v.celebrities_authorities}
                </Flex>
                <Flex title="0,00 À 0,04mg/l" xs={6} sm={4} md={4}>
                  {v.four_ml}
                </Flex>
                <Flex title="0,05 À 0,33mg/l" xs={6} sm={4} md={4}>
                  {v.thirtythree_ml}
                </Flex>
                <Flex title="> ou = 0,34mg/l" xs={6} sm={4} md={4}>
                  {v.thirtyfour_ml}
                </Flex>
                <Flex title="Quantidade de Testes Passivos Realizados" xs={6} sm={4} md={4}>
                  {v.passive_tests_performed}
                </Flex>
                <Flex title="Cnh Recolhidas" xs={6} sm={4} md={4}>
                  {v.cnh_collected}
                </Flex>
                <Flex title="Multados" xs={6} sm={4} md={4}>
                  {v.fined || 'não informado'}
                </Flex>
                <Flex title="Rebocados" xs={6} sm={4} md={4}>
                  {v.towed}
                </Flex>
                <Flex title="Deliberações de Remoção" xs={6} sm={4} md={4}>
                  {v.removal_resolutions}
                </Flex>
                <Flex title="Prisões por outros Meios de Prova Alcoolemia" xs={6} sm={4} md={4}>
                  {v.arrests_means_evidence}
                </Flex>
                <Flex title="Art. 307 Do CTB" xs={6} sm={4} md={4}>
                  {v.art307}
                </Flex>
                <Flex title="Ocorrências Diversas" xs={6} sm={4} md={4}>
                  {v.criminal_occurrences}
                </Flex>
                <Flex title="Dirigir Com CNH Cassada" xs={6} sm={4} md={4}>
                  {v.driving_canceled_license}
                </Flex>
                {v?.fineds?.length === 0 ? ''
              : (
                <ContentWapper>
                  {v?.fineds?.map((f: any, index: number) => (
                    <Grid container spacing={1} key={f.id}>
                      <Flex title="#" sm={3} md={3}>
                        {index+1}
                      </Flex>
                      <Flex title="Tipo" sm={3} md={3}>
                        {f.art}
                      </Flex>
                      <Flex title="Quantidade" sm={3} md={3}>
                        {f.quant}
                      </Flex>
                    </Grid>
                  ))}
                </ContentWapper>
              )}
                <Flex title="Observações Deliberalções de Veículos" xs={6} sm={4} md={4}>
                  {v.vehicle_resolutions || 'não informado'}
                </Flex>
                <Flex title="Observações Testes Administrativos" xs={6} sm={4} md={4}>
                  {v.administrative_tests || 'não informado'}
                </Flex>
              </Grid>
            </Accordion>
          </WrapperAccordion>
        ))}
        <Title>Alterações:</Title>
        <Grid container spacing={1}>
          <Flex title="Efetivo OLS" xs={12} sm={4} md={4}>
            {rcol?.change_ols || 'não informado'}
          </Flex>
          <Flex title="Equipe de Apoio" xs={12}>
            {rcol.change_support || 'não informado'}
          </Flex>
          <Flex title="Alterações de Material/Equipamento/Viatura" xs={6} sm={4} md={4}>
            {rcol.changes_material || 'não informado'}
          </Flex>
          <Flex title="Geral" xs={12}>
            {rcol.changes_general || 'não informado'}
          </Flex>
        </Grid>
      </Container>
      <ModalFactory />
    </Layout>
  );
};

export default Detail;
