import { IPaginate } from 'interfaces';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { GoChecklist } from 'react-icons/go';
import { useHttp } from 'hooks/http';
import { useQuery } from 'react-query';
import { Box, Grid } from '@material-ui/core';
import HeaderFilterPlus from '../../components/Headers/FilterPlus';
import Layout from '../../components/Layout';
import { Container } from './styles';
import Pagination from '../../components/Pagination';
import Card from './Card';

interface IFilter {
  search?: string;
}

const SocialActionList: React.FC = () => {
  const { httpGet } = useHttp();
  const [socialActions, setSocialActions] = useState([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  let params2: any = { ...paginate };
  const fetchSocialActions = async () => {
    
    if (filter) {
      params2 = {
        ...params2,
        // eslint-disable-next-line object-shorthand
        filter: filter,
      };
    }

    const response = await httpGet('social-action', {
      params: {
        page: paginate.page,
        perPage: paginate.perPage,
        search: filter?.search || '',
      },
    });

    return response.data;
  };

  const { data, status } = useQuery(['socialActions', paginate, filter], fetchSocialActions, {
    onSuccess: (data) => {
      setSocialActions(data?.data || [])
      setPaginate(data?.pagination || {})
    },
    refetchOnWindowFocus: false, 
    refetchInterval: 10000, retry: false,
  });

  useEffect(() => {
    if (status === 'success') {
      setSocialActions(data || []);
      setPaginate(data?.pagination || {});
    }
  }, [data, filter, status]);

  const handleSearch = useCallback(
    (value) => {
      setPaginate({ ...paginate, page: 1 });
      setFilter({ search: value });
    },
    [paginate],
  );

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  const breadcrumbs = {
    icon: GoChecklist,
    links: [{ path: '', title: 'Lista de Ações Sociais' }],
  };

 
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />

        <Grid container spacing={3} justify="space-between">
          <Box p={2}><b>Total</b></Box>
          <Box p={2}><b>{socialActions?.length}</b></Box>
        </Grid>

        {socialActions?.map((socialAction: any) => (
          <Card socialAction={socialAction} key={socialAction.id} />
        ))}

        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default SocialActionList;
