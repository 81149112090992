import { Grid } from '@material-ui/core';
import type { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import {
  Button, ButtonIcon, Input, Select, WrapperLabel,
} from 'components/Forms';
import Layout from 'components/Layout';
import { pt } from 'date-fns/locale';
import { useHttp } from 'hooks/http';
import { useToast } from 'hooks/toast';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import { EventNoteIcon, InfoIcon, PlusIcon, TrashIcon } from 'styles/icons';
import { bairrosRJ, municipiosRJ, regioesRJ } from 'utils/regioesRJ';
import * as Yup from 'yup';
import getValidationError from '../../utils/getValidationsErros';
import {
  Container, Content, GridButtons, WrapperBtnCancel, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';
import Flex from 'components/Flex';

registerLocale('pt-BR', pt);
interface IServiceRow {
  partner: string;
  service: string;
  quantity: string;
  services: { value: string; label: string }[];
}

const SocialAction: React.FC = () => {
  const history = useHistory();
  const labelRef = useRef<HTMLLabelElement>(null);
  const { httpGet, httpPost, loading } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [btnLoading, setBtnLoading] = useState(false);
  const [partners, setPartners] = useState<{ value: string; label: string }[]>([]);
  const [bairro, setBairro] = useState('');
  const [isBairroDisabled, setIsBairroDisabled] = useState(true);
  const [confirmedServices, setConfirmedServices] = useState<any[]>([]);
  const [bairroOptions, setBairroOptions] = useState<{ value: string; label: string; }[]>([]);
  // eslint-disable-next-line max-len
  const [filteredMunicipios, setFilteredMunicipios] = useState<{ value: string; label: string; region: string; }[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRegiao, setSelectedRegiao] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedServices, setSelectedServices] = useState<{ [partnerId: string]: string[] }>({});
  // eslint-disable-next-line max-len
  const [selectedMunicipio, setSelectedMunicipio] = useState<string | null>(null);
  const [serviceRows, setServiceRows] = useState<IServiceRow[]>([{
    partner: '', service: '', quantity: '', services: [],
  }]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, max-len
  const handleServiceRowChange = (index: number, field: keyof IServiceRow, value: any) => {
    const updatedRows = [...serviceRows];
    updatedRows[index][field] = value;

    if (field === 'service') {
      const partnerId = updatedRows[index].partner;
      setSelectedServices((prev) => {
        const updatedSelectedServices = { ...prev };

        const previousService = serviceRows[index].service;
        if (previousService && updatedSelectedServices[partnerId]) {
          updatedSelectedServices[partnerId] = updatedSelectedServices[partnerId].filter(
            (id) => id !== previousService
          );
        }

        updatedSelectedServices[partnerId] = [
          ...(updatedSelectedServices[partnerId] || []),
          value,
        ];

        return updatedSelectedServices;
      });
    }

    setServiceRows(updatedRows);
  };

  useEffect(() => {
    async function loadPartners(): Promise<void> {
      const { status, data } = await httpGet('social-action/partners');
      if (status === 200) {
        const stations = data
          .map((station: any) => ({
            value: station.id,
            label: `${station.partner_name}`,
          }));

        setPartners(stations);
      }
    }

    loadPartners();
  }, [httpGet]);

  const loadServicesByPartner = async (partnerId: string, index: number): Promise<void> => {
    const { status, data } = await httpGet(`social-action/partners-services/${partnerId}/services`);
    if (status === 200) {
      const servicesData = data.map((service: any) => ({
        value: service.id,
        label: service.service_name,
      }));

      const alreadySelectedServices = serviceRows
        .filter((row, rowIndex) => rowIndex !== index)
        .map(row => row.service);

      const filteredServices = servicesData.filter(
        (service: { value: string; }) => !alreadySelectedServices.includes(service.value)
      );

      const updatedRows = [...serviceRows];
      updatedRows[index].services = filteredServices;
      setServiceRows(updatedRows);
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleRegionChange = (selectedRegion: string) => {
    setSelectedRegiao(selectedRegion);
    setSelectedMunicipio(null);
    setFilteredMunicipios([]);
    setIsBairroDisabled(true);
    setBairro('');

    // eslint-disable-next-line max-len
    const municipiosFiltrados = municipiosRJ.filter((municipio) => municipio.region === selectedRegion);
    setFilteredMunicipios(municipiosFiltrados);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMunicipioChange = (selectedOption: any) => {
    setSelectedMunicipio(selectedOption.value);
    if (selectedOption.value === 'Rio de Janeiro') {
      setBairroOptions(bairrosRJ);
      setIsBairroDisabled(false);
      setBairro('');
    } else {
      setBairro('Fora da Capital');
      setIsBairroDisabled(true);
    }
  };

  const confirmService = () => {
    const lastRow = serviceRows[serviceRows.length - 1];

    if (lastRow.partner && lastRow.service && lastRow.quantity) {
      const partnerName = partners.find(p => p.value === lastRow.partner)?.label;
      const serviceName = lastRow.services.find(s => s.value === lastRow.service)?.label;

      setConfirmedServices(prev => [
        ...prev,
        {
          partner_id: lastRow.partner,
          service_id: lastRow.service,
          quantity: lastRow.quantity,
          partner_name: partnerName,
          service_name: serviceName,
        },
      ]);

      const updatedRows = [...serviceRows];
      updatedRows[updatedRows.length - 1] = {
        partner: '', service: '', quantity: '', services: [],
      };
      setServiceRows(updatedRows);
    }
  };

  const removeConfirmedService = (index: number) => {
    const updated = [...confirmedServices];
    updated.splice(index, 1);
    setConfirmedServices(updated);
  };

  const handleSubmit = useCallback(async (data: any) => {
    try {
      if (confirmedServices.length === 0) {
        addToast({
          type: 'error',
          title: 'É necessário adicionar ao menos um serviço antes de enviar.',
        });
        setBtnLoading(false);
        return;
      }

      setBtnLoading(true);

      const formattedDataAcao = new Date(data.data_acao).toISOString().split('T')[0];

      const socialActionPayload = {
        data_acao: formattedDataAcao,
        regiao: data.regiao.value,
        municipio: data.municipio.value,
        bairro: data.bairro?.value || 'Fora da Capital',
      };

      const socialActionResponse = await httpPost('social-action', socialActionPayload);

      if (socialActionResponse.status === 201) {
        const socialActionId = socialActionResponse.data.id;

        const serviceDetails = confirmedServices.map((service: any) => ({
          social_action_id: socialActionId,
          partner_id: service.partner_id,
          service_id: service.service_id,
          quantity: service.quantity,
        }));

        const serviceResponse = await httpPost('social-action/details', {
          services: serviceDetails,
        });

        if (serviceResponse.status === 201) {
          addToast({ title: 'Ação social e detalhes salvos com sucesso!', type: 'success' });
        } else {
          addToast({ title: 'Erro ao salvar os detalhes!', type: 'error' });
        }
        try {
          history.push('/social-action/list');
        } catch (error) {
          console.log(error)
        }
      } else {
        addToast({ title: 'Erro ao salvar a ação social!', type: 'error' });
      }
      setBtnLoading(false);
    } catch (err) {
      setBtnLoading(false);
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, history, httpPost, confirmedServices]);

  const breadcrumbs = {
    icon: EventNoteIcon,
    links: [{ path: '', title: 'Ação Social' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <Grid container spacing={1}>
              <WrapperLabel label="Data da Ação" xs={12} md={6} sm={6}>
                <Input
                  name="data_acao"
                  type="date"
                />
              </WrapperLabel>
              <WrapperLabel label="Região" xs={12} md={6} sm={6}>
                <Select
                  name="regiao"
                  options={regioesRJ}
                  onChange={(selectedOption: any) => handleRegionChange(selectedOption.value)}
                />
              </WrapperLabel>
              <WrapperLabel label="Município" xs={12} md={6} sm={6}>
                <Select
                  name="municipio"
                  options={filteredMunicipios}
                  // eslint-disable-next-line max-len
                  value={selectedMunicipio ? { value: selectedMunicipio, label: selectedMunicipio } : null}
                  onChange={handleMunicipioChange}

                />
              </WrapperLabel>
              <WrapperLabel label="Bairro" xs={12} md={6} sm={6}>
                <Select
                  name="bairro"
                  options={bairroOptions}
                  value={bairro ? { value: bairro, label: bairro } : null}
                  onChange={(e: any) => setBairro(e.value)}
                  // eslint-disable-next-line max-len
                  isDisabled={isBairroDisabled || !selectedMunicipio}
                />
              </WrapperLabel>
           
              {serviceRows.map((row, index) => (
                <>
                  <WrapperLabel label="Parceiro" xs={10} md={4} sm={5}>
                    <Select
                      name={`services[${index}].partner_id`}
                      value={
                        row.partner
                          ? { value: row.partner, label: partners.find(p => p.value === row.partner)?.label }
                          : null
                      }
                      options={partners}
                      onChange={(selectedOption: any) => {
                        handleServiceRowChange(index, 'partner', selectedOption.value);
                        loadServicesByPartner(selectedOption.value, index);
                      }}
                    />
                  </WrapperLabel>
                  <WrapperLabel label="Serviço" xs={10} md={4} sm={5}>
                    <Select
                      name={`services[${index}].service_id`}
                      value={
                        row.service
                          ? { value: row.service, label: row.services.find(s => s.value === row.service)?.label }
                          : null
                      }
                      options={row.services}
                      onChange={(selectedOption: any) => handleServiceRowChange(index, 'service', selectedOption.value)}
                    />
                  </WrapperLabel>
                  <WrapperLabel label="Quantidade" xs={4} sm={3}>
                    <Input
                      name={`services[${index}].quantity`}
                      value={row.quantity}
                      onChange={(e: any) => handleServiceRowChange(index, 'quantity', e.target.value)}
                    />
                    <ButtonIcon title="Adicionar Serviço" onClick={confirmService}>
                      <PlusIcon />
                    </ButtonIcon>
                  </WrapperLabel>
                </>
              ))}
            {confirmedServices.map((item, index) => (
                <Container title="Parceiros presentes e serviços disponibilizados: " key={index}>
                  <Grid container spacing={1} justify="space-between" style={{ display: "flex" }}>
                    <Flex title="Parceiro" xs={12} sm={4} md={4}>
                      {item.partner_name}
                    </Flex>
                    <Flex title="Parceiro" xs={12} sm={4} md={4}>
                      {item.service_name}
                    </Flex>
                    <Flex title="Quantidade" xs={12} sm={4} md={3}>
                      {item.quantity}
                    </Flex>

                    <Grid item xs={12} sm={1} md={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <TrashIcon
                        onClick={() => removeConfirmedService(index)}
                        color="red"
                        cursor="pointer"
                        title="Remover"
                      />
                    </Grid>                
                  </Grid>
                </Container>
              ))}
            <GridButtons>
              <WrapperBtnInfo>
                <ButtonIcon
                  title="Instruções"
                  onClick={() => labelRef.current?.click()}
                >
                  <InfoIcon />
                </ButtonIcon>
              </WrapperBtnInfo>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => { history.push('/social-action/list'); }}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button
                  type="submit"
                  isLoading={btnLoading}
                >
                  Enviar
                </Button>
              </WrapperBtnSubmit>
            </GridButtons>
            </Grid>
          </Content>
        </UForm>
      </Container >
    </Layout >
  );
};

export default SocialAction;
