import React from 'react';
import MPagination from '@material-ui/lab/Pagination';

import { Container } from './styles';

import { IPaginate } from '../../interfaces';

interface IFunction {
  (paginate: IPaginate): void;
}

interface IPagination {
  paginate: IPaginate;
  handlePaginate: IFunction
}

const Pagination: React.FC<IPagination> = ({ paginate, handlePaginate }) => {
  const handleChange = (event: React.ChangeEvent<unknown>, page: number): void => {
    handlePaginate({ ...paginate, page });
  };
  return (
    <Container>
      <MPagination count={paginate?.lastPage} page={paginate?.page} onChange={handleChange} />
    </Container>
  );
};

export default Pagination;
