/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Form as UForm } from '@unform/web';
import Layout from 'components/Layout';
import { useHttp } from 'hooks/http';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import {
  Button, ButtonIcon, Select, WrapperLabel,
} from 'components/Forms';
import { useHistory } from 'react-router-dom';
import getValidationErrors from 'utils/getValidationsErros';
import { useToast } from 'hooks/toast';
import { EventNoteIcon, InfoIcon } from 'styles/icons';
import {
  Container, Content, GridButtons, WrapperBtnCancel, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';

const PartnerService: React.FC = () => {
  const history = useHistory();
  const { httpGet, httpPost, loading } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const { addToast } = useToast();
  const [partners, setPartners] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    async function loadPartners(): Promise<void> {
      const { status, data } = await httpGet('social-action/partners/all');
      if (status === 200) {
        const partners = data
          .map((partner:any) => ({
            value: partner.id,
            label: `${partner.partner_name}`,
          }));

        setPartners(partners);
      }
    }

    async function loadServices(): Promise<void> {
      const { status, data } = await httpGet('social-action/services/all');
      if (status === 200) {
        const services = data
          .map((service:any) => ({
            value: service.id,
            label: `${service.service_name}`,
          }));

        setServices(services);
      }
    }
    loadPartners();
    loadServices();
  }, []);

  const handleSubmit = useCallback(async (data : any) => {
    try {
      setBtnLoading(true);
      // eslint-disable-next-line no-underscore-dangle
      const _partner_service = {
        ...data,
        partner_id: data?.partner_id.value,
        service_id: data?.service_id.value,
      };
      const schema = Yup.object().shape({
        partner_id: Yup.string().required('Nome do parceiro é obrigatório'),
        service_id: Yup.string().required('Nome do serviço é obrigatório'),
      });

      await schema.validate(_partner_service, { abortEarly: false });

      const { status } = await httpPost('/social-action/partners-services', {
        ..._partner_service,
      });
      setBtnLoading(false);
      if (status === 201) {
        addToast({ title: 'Salvo com suceso!', type: 'success' });
        formRef.current?.reset();
        history.push('/social-action/partners-services/create');
      } else {
        addToast({ title: 'Não foi possível' });
        formRef.current?.reset();
        history.push('/social-action/partners-services/create');
      }
    } catch (error) {
      setBtnLoading(false);
      if (error instanceof Yup.ValidationError) {
        const erros = getValidationErrors(error);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, history, httpPost]);

  const breadcrumbs = {
    icon: EventNoteIcon,
    links: [{ path: '', title: 'Parceiro - Serviço' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Nome parceiro" xs={12} md={6} sm={6}>
                <Select
                  name="partner_id"
                  options={partners}
                />
              </WrapperLabel>
              <WrapperLabel label="Nome serviço" xs={12} md={6} sm={6}>
                <Select
                  name="service_id"
                  options={services}
                />
              </WrapperLabel>
            </Grid>
            <GridButtons>
              <WrapperBtnInfo>
                <ButtonIcon
                  title="Instruções"
                  onClick={() => labelRef.current?.click()}
                >
                  <InfoIcon />
                </ButtonIcon>
              </WrapperBtnInfo>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => { history.push('/'); }}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button
                  type="submit"
                  isLoading={btnLoading}
                >
                  Enviar
                </Button>
              </WrapperBtnSubmit>
            </GridButtons>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default PartnerService;
