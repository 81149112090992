import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useHttp } from 'hooks/http';
import Layout from 'components/Layout';
import { Grid } from '@material-ui/core';
import { ListIcon } from 'styles/icons';
import {
  Container, Content,
} from './styles';
import { WrapperLabel, Button } from '../../../components/Forms';
import distanceDateTime from 'utils/distanceDateTime';

interface ISocialAction {
  regiao: string;
  user: string;
  bairro: string;
  municipio: string;
  data_acao: string;
  created_at: string;
  server_time: string;
  details: Array<{
    partner_id: string;
    partner_name: string;
    service_id: string;
    service_name: string;
    quantity: number;
  }>;
}

const Detail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { httpGet, loading } = useHttp();
  const [socialAction, setSocialAction] = useState<ISocialAction | null>(null);
  const [serviceRows, setServiceRows] = useState([{
    partner: '', service: '', quantity: '', services: [],
  }]);

  useEffect(() => {
    async function loadActionDetails(): Promise<void> {
      const { status, data } = await httpGet(`/social-action/${id}`);
      if (status === 200) {
        setSocialAction(data);

        const rows = data.details.map((detail: any) => ({
          partner: detail.partner_name,
          service: detail.service_name,
          quantity: detail.quantity,
        }));

        setServiceRows(rows);
      }
    }

    loadActionDetails();
  }, [id, httpGet]);

  const breadcrumbs = {
    icon: ListIcon,
    links: [
      { path: '/', title: 'Ação Social' },
      { path: '', title: 'Detalhe da Ação Social' },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
  
    return `${day}/${month}/${year}`;
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <Grid container spacing={1}>
            <WrapperLabel label="Criado por" xs={12} md={6} sm={6}>
              {socialAction?.user || 'N/A'}
            </WrapperLabel>

            <WrapperLabel label="Criado há" xs={12} md={6} sm={6}>
              {socialAction && socialAction.created_at && socialAction.server_time
                ? distanceDateTime(socialAction.created_at, socialAction.server_time, false)
                : 'Tempo não disponível'}
            </WrapperLabel>

          </Grid>
        </Content>

        <div style={{ marginTop: '1rem' }} />

        <Content>
          <Grid container spacing={1}>
            <WrapperLabel label="Data" xs={12} md={6} sm={6}>
              {socialAction?.data_acao ? formatDate(socialAction.data_acao) : 'N/A'}
            </WrapperLabel>

            <WrapperLabel label="Região" xs={12} md={6} sm={6}>
              {socialAction?.regiao || 'N/A'}
            </WrapperLabel>

            <WrapperLabel label="Município" xs={12} md={6} sm={6}>
              {socialAction?.municipio || 'N/A'}
            </WrapperLabel>

            <WrapperLabel label="Bairro" xs={12} md={6} sm={6}>
              {socialAction?.bairro || 'N/A'}
            </WrapperLabel>
          </Grid>
        </Content>

        <div style={{ marginTop: '1rem' }} />

        <Content>
          <Grid container spacing={2}>
            {serviceRows.map((row, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={4}>
                  <WrapperLabel label="Parceiro" xs={10} md={4} sm={5}>
                    {row.partner || 'N/A'}
                  </WrapperLabel>
                </Grid>

                <Grid item xs={12} md={4}>
                  <WrapperLabel label="Serviço" xs={10} md={4} sm={5}>
                    {row.service || 'N/A'}
                  </WrapperLabel>
                </Grid>

                <Grid item xs={12} md={4}>
                  <WrapperLabel label="Quantidade" xs={4} sm={3}>
                    {row.quantity || 'N/A'}
                  </WrapperLabel>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          <Button type="button" onClick={() => { history.push('/social-action/list'); }}>
            Voltar
          </Button>
        </Content>
      </Container>
    </Layout>
  );
};

export default Detail;
